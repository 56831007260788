@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rowdies&display=swap');


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.dot {
  height: 10px;
  width: 10px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
}

.Hotspot {
  display: block;
  background: white;
  border-radius: 10px;
  border: 0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
  box-sizing: border-box;
  cursor: pointer;
  height: 10px;
  padding: 0px;
  position: relative;
  transition: opacity 0.3s;
  width: 10px;
}

.Hotspot:not([data-visible]) {
  background: transparent;
  border: 1px solid white;
  box-shadow: none;
  height: 10px;
  pointer-events: none;
  width: 10px;
}

.Hotspot:focus {
  border: 1px solid rgb(0, 128, 200);
  height: 10px;
  outline: none;
  width: 10px;
}

.Hotspot>* {
  opacity: 1;
  transform: translateY(-50%);
}

.HotspotAnnotation {
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
  color: white;
  display: block;
  font-family: Futura, Helvetica Neue, sans-serif;
  font-size: 10px;
  font-weight: 700;
  left: calc(100% + 1em);
  max-width: 128px;
  padding: 0.5em 1em;
  position: absolute;
  top: 50%;
  width: max-content;
  /* background-color: #ffffff5c; */
}

.Hotspot:not([data-visible])>* {
  opacity: 0;
  pointer-events: none;
  transform: translateY(calc(-50% + 4px));
  transition: transform 0.3s, opacity 0.3s;
}

.offanotation {
  width: 50px;
  height: 50px;
  text-align: center;
  position: absolute;
  bottom: 0px;
  background: #50e3c29e;
  border: none;
  font-size: 9px;
}